@import "../../assets/scss/variables.scss";

.playground_height {
  height: calc(100vh - 60px);
  overflow: auto;
}

.cart_label {
  font-family: $firaSans;
  font-size: $font-20;
  font-weight: $medium;
  text-align: left;
  color: $primaryColor;
  margin: 0;
}

.illustration_header {
  font-size: $font-20;
  font-family: $firaSans;
  text-align: center;
  color: $primaryColor;
  margin: 10px;
}

.illustration_body {
  font-size: $font-14;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  color: $secondaryColor;
}

.order_list_wrapper {
  width: 700px;
  margin: 0 auto;
}

.padding_20 {
  padding: 20px;
}

.orders_card {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba($primaryColor, 0.15);
  background-color: $white;
  // cursor: pointer;
}

.card_header_title {
  font-family: $firaSans;
  font-size: $font-20;
  font-weight: $medium;
  text-align: left;
  color: $primaryColor;
  margin: 0;
}

.card_body_text {
  font-size: $font-16;
  text-align: left;
  color: $secondaryColor;
  margin: 0;
}

.product_name {
  width: 90%;
  font-size: $font-18;
  font-weight: $medium;
  color: $primaryColor;
  margin: 0;
}

.address_type_label {
  font-size: $font-14;
  color: #a1a1a1;
  margin: 0;
}

.address_name_and_phone {
  font-size: $font-16;
  font-weight: $medium;
  text-align: left;
  color: $primaryColor;
  margin: 0;
}

.address_line_1 {
  font-size: $font-14;
  text-align: left;
  color: $primaryColor;
  margin: 0;
}

.address_line_2 {
  font-size: $font-14;
  text-align: left;
  color: $secondaryColor;
  margin: 0;
}

.product_price {
  font-size: $font-16;
  font-weight: $medium;
  color: $secondaryColor;
  margin: 0;
}

.status_label {
  font-size: $font-14;
  margin: 0;
  color: $primaryColor;
  text-align: left;
}

.status_chip {
  padding: 2px;
  border-radius: 36px;
  text-align: center;
}

.status_text {
  font-size: $font-12;
  text-align: center;
  margin: 0;
}

.quantity_count {
  font-size: $font-14;
  color: #222222;
  margin: 0;
  min-width: 100px;
  text-align: center;
  font-weight: 700;
}

.secondary_action,
.secondary_action_loading {
  width: 100px;
  height: 35px;
  background-color: $white;
  border: 1px solid $secondaryColor;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-14;
  font-weight: $medium;
  color: $secondaryColor;
}

.secondary_action:hover {
  background-color: $secondaryColor;
  color: $white;
  transition: all 0.3s;
}

.primary_action,
.primary_action_loading {
  width: 100px;
  height: 35px;
  background-color: $white;
  border: 1px solid $accentColor;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-14;
  font-weight: $medium;
  color: $accentColor;
}

.cancel_return_button {
  width: 130px !important;
}

.primary_action:hover {
  background-color: $accentColor;
  color: $white;
  transition: all 0.3s;
}

button:disabled {
  opacity: 0.5;
}

.label_chip {
  padding: 4px 8px;
  background-color: #efefef;
  border: 0.5px solid #ddd;
  border-radius: 36px;
}

.label_chip_text {
  margin: 0;
  font-size: $font-12;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .order_list_wrapper {
    width: 100%;
  }
}
