#map {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 99;
  /* border-radius: 10px !important; */
}

#mmiPickerTop {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}

/* .leaflet-left{*/
/*    display: none !important;*/
/*} */

.leaflet-right {
  display: none !important;
}

#Dv_mmiSearch_map {
  font-size: 14px;
}

.mmi_search_dv .highligher-search {
  font-size: 14px;
}

.resultMmi_img {
  margin-left: 4px;
  margin-top: 7px;
}

.bouncing {
  -webkit-animation-duration: .7s;
  animation-duration: .7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {

  0%,
  100% {
    -webkit-transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-30px);
  }
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}